import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setOverlayVisible, startVolume } from '../../../store/stream/stream-slice';
import { AppDispatch } from '../../../store/store';
import { establishConnection } from '../../../store/stream/stream-operations';
import { Link } from 'react-router-dom'; // Импортируем Link для навигации
import './AllowVolume.scss';
import { setAgree, setPrivacy } from '../../../store/chat/chat-slice';


const AllowVolume: React.FC= () => {
  const dispatch: AppDispatch = useDispatch();
  const [isCookieBannerVisible, setCookieBannerVisible] = useState(true);
  const [isButtonEnabled, setButtonEnabled] = useState(false);
  const textContainerRef = useRef<HTMLDivElement>(null);

  const handleAcceptVolume = async () => {
    try {

      await navigator.mediaDevices.getUserMedia({ audio: true });
      setCookieBannerVisible(false);
      dispatch(setOverlayVisible(false));
      dispatch(establishConnection());
      dispatch(startVolume(true));
      dispatch(setAgree(true))
    } catch (error) {

    }
  };

  const handleScroll = () => {
    const container = textContainerRef.current;
    if (container) {
      const isScrolledToBottom =
        Math.abs(container.scrollHeight - container.scrollTop - container.clientHeight) < 1;
      setButtonEnabled(isScrolledToBottom);
    }
  };

  const handlePrivacy = () => {
    dispatch(setPrivacy(true));
  }

  return (
    <>
      {isCookieBannerVisible && (
        <div className="cookie-banner" id="cookie-banner">
          <div
            className="cookie-text"
            ref={textContainerRef}
            onScroll={handleScroll}
          >
            <p>
              Настоящий ИИ-Аватар и размещенная в нем информация предназначены
              только для медицинских работников и могут быть использованы медицинскими работниками только в образовательных и/или справочно-информационных целях.
            </p>
            <p>
              Настоящий ИИ-Аватар является способом представления информации из массивов опубликованных данных по терапии соответствующих заболеваний; клинических рекомендаций; иных официальных документов и/или опубликованных источников в визуально доступной форме.
            </p>
            <p>
              Настоящий ИИ-Аватар <span>НЕ ЯВЛЯЕТСЯ МЕДИЦИНСКИМ ИЗДЕЛИЕМ.</span> ИИ-Аватар НЕ ПРЕДНАЗНАЧЕН ДЛЯ ПОСТАНОВКИ ДИАГНОЗОВ, НАЗНАЧЕНИЯ ЛЕЧЕНИЯ КОНКРЕТНЫМ ПАЦИЕНТАМ, ОПРЕДЕЛЕНИЯ ПОРЯДКА ЗАПОЛНЕНИЯ РЕЦЕПТУРНЫХ БЛАНКОВ.
            </p>
            <p>
              Настоящий ИИ-Аватар НЕ предназначен для оказания медицинской помощи либо использования при оказании медицинской помощи пациентам, в том числе в клинической и лабораторной диагностике, для профилактики и лечения заболеваний.
            </p>
            <p>
              Настоящий ИИ-Аватар не сохраняет или каким-либо иным образом не аккумулирует введённые данные и информацию, а все данные приводятся одномоментно в режиме реального времени.
            </p>
            <p>
              НАЖИМАЯ «ПРОДОЛЖИТЬ» ВЫ ПОДТВЕРЖДАЕТЕ, ЧТО ПОЛНОСТЬЮ ОЗНАКОМИЛИСЬ С НАСТОЯЩИМИ ПРАВИЛАМИ, ВЫ ПОНИМАЕТЕ И ПОЛНОСТЬЮ ПРИНИМАЕТЕ ИХ СОДЕРЖАНИЕ, ПОДТВЕРЖДАЕТЕ ДАННЫЕ ВЫШЕ ЗАВЕРЕНИЯ, А ТАКЖЕ В ДАЛЬНЕЙШЕМ ОБЯЗУЕТЕСЬ СОБЛЮДАТЬ УКАЗАННЫЕ ПРАВИЛА, А ТАКЖЕ СОГЛАШАЕТЕСЬ С <span><a onClick={handlePrivacy}>УСЛОВИЯМИ ИСПОЛЬЗОВАНИЯ СЕРВИСА.</a></span>
            </p>
          </div>
          <button
            id="connect-button"
            onClick={handleAcceptVolume}
            disabled={!isButtonEnabled}
          >
            Продолжить
          </button>
        </div>
      )}
    </>
  );
};

export default AllowVolume;
